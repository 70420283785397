<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <flixCheckPermissions role="calendar" permission="3" error>
          <div class="flix-container">
            <calendarAssistentDelete />
          </div>
        </flixCheckPermissions>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    calendarAssistentDelete () { return import('@/components/assistent/delete.vue') }
  }
}
</script>
